<template>
  <div class="container my-5">
    <div class="text-head h2 font-weight-bold py-0 my-0 text-black">
      My Profile
    </div>
    <div @click="goBack">
      <span class="s-18 fw-400 cursor-pointer text-black">
        <img src="../../assets/gobacck.png" class="w-12 h-12" alt="" /> Go
        back</span
      >
    </div>

    <div
      class="card mx-auto shadow-sm"
      style="max-width: 600px; background-color: #eef1ff"
    >
      <div class="text-center mt-4">
        <img
          v-if="churchData.logoUrl"
          :src="churchData.logoUrl"
          alt="Church Logo"
          class="rounded-circle"
          width="80"
        />
        <a v-else class="logo-link col-md-12 d-flex justify-content-center"
          ><img src="../../assets/churchplusblueLogo.png" alt="Churchplus Logo"
        /></a>
      </div>

      <div class="card-body">
        <div class="p-3 bg-white rounded">
          <h5 class="fw-400 mb-3">Church Details</h5>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Name:</span>
            {{ churchData.churchName }}
          </p>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Aka/Parish:</span>
            {{ churchData.aka }}
          </p>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Address:</span
            >{{ churchData.address }}
          </p>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Phone number:</span>
            {{ churchData.phoneNumber }}
          </p>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Email:</span
            >{{ churchData.email }}
          </p>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Country:</span>
            {{ selectCountry.name }}
          </p>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Time zone:</span
            >{{ churchData.timeZone }}
          </p>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Website URL:</span>
            <a
              :href="churchData.websiteUrl"
              class="text-black"
              target="_blank"
              >{{ churchData.websiteUrl }}</a
            >
          </p>
        </div>

        <h5 class="mt-4 font-weight-bold">Pastor Details</h5>
        <div class="p-3 bg-white rounded">
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Name:</span>
            {{ churchData.headPastorName }}
          </p>
          <p class="s-18 fw-400">
            <span class="font-weight-bold s-18">Email:</span>
            {{ churchData.headPastorEmail }}
          </p>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-md-4">
            <div class="text-center mt-4">
              <router-link
                to="/tenant/settings/profile"
                class="border-0 text-decoration-nonw"
              >
                <el-button round class="primary-bg w-100 py-4 text-white">
                  Edit profile
                </el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import router from "../../router";
import axios from "@/gateway/backendapi";

const countries = ref([]);
const initialEmail = ref("");
const churchData = ref({});
const selectTimeID = ref(null);
const selectCountry = ref({});
const selectCountryID = ref(null);
const selectTime = ref({});

const getCountries = async () => {
  try {
    const { data } = await axios.get("/api/GetAllCountries");
    data.sort((a, b) => a.data - b.data);
    console.log(data);
    countries.value = data;
    getChurchProfile();
  } catch (error) {
    getChurchProfile();
    console.log(error);
  }
};
getCountries();

const getChurchProfile = async () => {
  try {
    const { data } = await axios.get("/mobile/v1/Profile/GetChurchProfile");
    churchData.value = data.returnObject;
    initialEmail.value = data.returnObject.email;

    selectCountry.value = countries.value.find((i) => {
      return i.id === churchData.value.countryID;
    });
    selectCountryID.value = selectCountry.value
      ? selectCountry.value.id
      : selectCountry.value;

    selectTime.value = TimeZone.timeZones.find(
      (i) => i.value == churchData.value.timeZone
    );
    selectTimeID.value = selectTime.value
      ? selectTime.value.value
      : selectTime.value;

    console.log(churchData.value);
  } catch (error) {
    console.log(error);
  }
};

const goBack = () => {
  router.go(-1);
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  padding: 20px;
}
.logo-link img {
  width: 7rem;
  height: 5rem;
}
</style>
